import { useEffect, useState } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { getRequestParam } from '@myci/utils';

export const useQueryParam = (queryParam: string) => {
	const location = useLocation();
	const [queryParamValue, setQueryParamValue] = useState<string | string[] | null>(null);
	const urlParam = getRequestParam(queryParam)({ location });

	// NOTE: this might seem unnecessary (instead of returning the urlParam directly),
	// but the state update is actually needed as a workaround for Gatsby's inability to correctly
	// perform the query string related effect in production build
	useEffect(() => {
		setQueryParamValue(urlParam);
	}, [urlParam]);

	return queryParamValue;
};
